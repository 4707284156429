<template>
  <collections-list :company_id="company_id"></collections-list>
</template>

<script>
import CollectionsList from './CollectionsList.vue'

export default {
  components: { CollectionsList },
  data() {
    return {
      company_id: this.$route.params?.id
    }
  },
  mounted() {
  }
}
</script>
